<template>
  <div class="">
    <el-table
      v-loading="demandConfig.isLoadData"
      :data="demandConfig.data"
      style="width: 100%">
      <el-table-column
        label="标题/赏金">
        <template slot-scope="scope">
          <span style="margin-left: 10px;font-weight: bold;">类型：{{ scope.row.reqFlag }}+{{
              scope.row.typeName
            }}</span><br>
          <span style="margin-left: 10px;color: red;font-weight: bold;">{{
              scope.row.budget_scope ? '¥' + scope.row.minbudget + '-' + scope.row.maxbugget : '无预算'
            }}</span>
          <router-link :to="'/requirement/view/' + scope.row.requireid">
            <a class="sctp-ellipsis" style="margin: 0 10px;color: #0766cc;font-weight: bold;"
               href="#">{{ scope.row.title }}</a>
            <a class="sctp-ellipsis" style="margin: 0 10px;" href="#">{{ scope.row.reqdesc }}</a>
          </router-link>
          <template v-if="scope.row.attachment">
            <div class="mg-t5 mg-lr10 flex">
              附件：
              <a target="_blank" :href="scope.row.attachment"
                 class="flex sctp-flex-aic">
                <i class="el-icon-document mg-r5"></i>
                {{ attachmentName(scope.row.attachment, scope.row.requireid) }}
              </a>/
            </div>
          </template>
          <span style="margin-left: 10px">{{ scope.row.hits || 0 }}人浏览</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="发布时间"
        width="170">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ dateToString(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="截止时间"
        align="center"
        width="170">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ dateToString(scope.row.expectdate) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="收藏时间"
        align="center"
        width="170">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ dateToString(scope.row.collectionTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="100">
        <template slot-scope="scope">
          <el-button
            @click="cancelFavorite(scope.row)"
            type="danger" size="mini">
            取消收藏
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white sctp-mar-t10">
      <el-pagination
        :current-page="demandConfig.pageConfig.page"
        :page-size="demandConfig.pageConfig.limit"
        @size-change="demandConfig.pageConfig.handleSizeChange"
        @current-change="demandConfig.pageConfig.handleCurrentChange"
        :page-sizes="demandConfig.pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="demandConfig.pageConfig.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Random from "@/resources/js/random";
import {collection} from "../../../../apis";

export default {
  name: 'favorite-demand',
  data() {
    return {
      demandConfig: {
        isLoadData: false,
        data: [],
        loadData: () => {
          let {page, limit} = this.demandConfig.pageConfig;
          this.demandConfig.isLoadData = true;
          collection.getDemand({
            userId: this.user.userId,
            page, limit,
          }).then(res => {
            let {data, count = 0} = res;
            this.demandConfig.data = data
            this.demandConfig.pageConfig.total = count;
          }).finally(() => {
            this.demandConfig.isLoadData = false;
          });
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.demandConfig.pageConfig.limit = limit;
            this.demandConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.demandConfig.pageConfig.page = page;
            this.demandConfig.loadData();
          }
        },
      }
    }
  },
  methods: {
    cancelFavorite(item) {
      collection.addFavorite({
        userId: (this.user && this.user.userId) || null,
        targetId: item.requireid,
        targetType: 3
      }).then(res => {
        const {data} = res;
        if (data) {
          this.$message.success('收藏成功')
        } else {
          this.$message.success('取消收藏成功')
        }
        this.demandConfig.loadData();
      });
    }
  },
  computed: {
    attachmentName() {
      return function (fileName, demandId) {
        if (!fileName) {
          return '';
        }
        let suffix = fileName.substring(fileName.lastIndexOf('.'));
        fileName = fileName.substring(fileName.indexOf('-'), fileName.lastIndexOf('-')).replace(
          /-/g, '');
        return `${demandId}${fileName}${Random.instance.MathRand(4)}${suffix}`;
      }
    },
  },
  beforeMount() {
    this.demandConfig.loadData();
  }
}
</script>

<style scoped>
/*@formatter:off*/

  /*@formatter:on*/
</style>
